import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Navigate } from "react-router-dom";

import { toast } from "react-toastify";

/**
 * Code snippet for creating an API using Redux Toolkit's createApi function.
 * The API is configured with a base query that includes a base URL, headers with an authorization token,
 * and a function to validate the response status.
 *
 * Inputs:
 * - process.env.REACT_APP_BASE_URL_LOCAL: a string representing the base URL for the API
 * - prepareHeaders: a function that takes in headers and adds an authorization token to them if it exists in local storage
 * - validateStatus: a function that takes in the response and result and checks if the response status is 200 or 201,
 *   or if it's 401, in which case it clears local storage and redirects to the login page,
 *   or else it displays an error message using the react-toastify library
 *
 * Outputs:
 * - api: an object that includes a reducer and a set of endpoints,
 *   which can be used to make API requests and manage their state using Redux Toolkit
 *
 * Additional aspects:
 * - The code snippet uses the react-toastify library to display error messages to the user.
 * - The code snippet assumes that there is a token stored in local storage,
 *   and will not add an authorization header if there isn't one.
 */

// Adding token to request headers
const prepareHeaders = (headers) => {
  const token = localStorage.getItem("token");

  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

// Validating response status and handling errors
const validateStatus = (res, result) => {
  const { status } = res;
  if (status === 200 || status === 201) return result;
  else if (status === 401) {
    toast.error("Unauthorized");
    localStorage.clear();
    setTimeout(() => {
      window.location.replace("/");
    }, [2000]);
    return result;
  } else if (status === 404) {
    // if (result?.path === "/admin/login" ) {
    //   toast.error(result?.message);
    // }
    return;
  } else if (status === 409) {
    toast.error(result?.message);
  } else if (status === 400) {
    if (result?.path === "/customerStepsMapping") {
      toast.error(result?.message);
    }
    else if(result?.path==='/document')
    {
      return result
    }
    else if(res.url.split('/')[res.url.split('/').length-2] =='azure-extractor')
    {
      return toast.error(result.message)
    }
    else if (result?.path?.includes("/admin/password/")) {
      toast.error("Invalid credentials!");
    } else toast.error("Something went wrong!! Please try again later...");
  } else toast.error("Something went wrong!! Please try again later...");
  // } else toast.error(result?.message);
};

// Creating the API using Redux Toolkit's createApi function
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders,
    validateStatus,
  }),
  endpoints: () => ({}),
});
