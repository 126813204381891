enum API {
  LOGIN = "process-manager/admin/login",
  UPDATE_ADMIN_PASSWORD = "process-manager/admin/password",
  UPDATE_AGENT_PASSWORD = "process-manager/agent/password",
  COMPANY = "process-manager/company",
  CUSTOMERS = "process-manager/customer",
  CUSTOMER_DASHBOARD = "process-manager/customerDashboard/count",
  COUNTRY = "process-manager/country",
  AGENT = "process-manager/agent",
  BANNER = "process-manager/banners",
  SETTINGS = "process-manager/settings",
  SIGNUP = "process-manager/auth/signup",
  LOGOUT = "process-manager/auth/logout",
  FORGOT_PASSWORD = "process-manager/auth/forgot-password",
  RESET_PASSWORD = "process-manager/auth/reset-password",
  VALIDATE_RESET_PASSWORD = "process-manager/auth/reset-password/validate",
  REFRESH_TOKEN = "process-manager/auth/token",
  PROFILE = "process-manager/user/profile",
  PROCESS_LIST = "process-manager/process",
  TASKS = "process-manager/task",
  TASKS_CUSTOMER = "process-manager/task/customer/V2",
  PROCESS_TYPE = "process-manager/processType",
  DASHBOARD = "process-manager/dashboard/count",
  PROCESS_SEARCH = "process-manager/process/search",
  PROCESS_TYPE_SEARCH = "process-manager/processType/search",
  STEPS = "process-manager/steps",
  NOTIFICATION = "process-manager/notification",
  CUSTOMER_STATUS_LIST = "/customerStatusList",
  STEP_DATA_UPLOAD = "process-manager/customerStepsMapping",
  UPLOADED_DOC_DATA = "process-manager/document/customerStep/",
  CHAT_LIST = "chat-service/session/getAllSessions/",
  CHAT_HISTORY = "chat-service/session/getAllMessages/",
  SOCKET_SERVICE = "chat-service-socket",
  REPORT = "process-manager/report",
  IMAGE_VALIDATION_DOCUMENTS = "pm-clarify/documents/",
  IMAGE_VALIDATE = "pm-clarify/image-validate/",
  DOCUMENT='process-manager/document',
  DOC_OCR = "pm-clarify/azure-extractor/",
  AGENT_CUSTOMER_MAPPING = "process-manager/agentCustomerMapping",
  RESTART_SERVICES = "restartservices",
  INCOMING_NOTIFICATION = "process-manager/notification/all",
  CUSTOMER_DOCS = "process-manager/document/customer",
  CUSTOMER_DOCS_REMINDER = "pm-scheduler/api/reminders/document",
  ADD_CUSTOMER_DOCS_REMINDER = "pm-scheduler/api/reminders/document/",
  EDIT_CUSTOMER_DOCS_REMINDER = "pm-scheduler/api/reminders/bulk-update",
  DOCUMENT_UPDATE = 'process-manager/document'

}

const publicEndpoints = [
  API.FORGOT_PASSWORD,
  API.LOGIN,
  API.RESET_PASSWORD,
  API.LOGOUT,
  API.REFRESH_TOKEN,
  API.RESET_PASSWORD,
  API.SIGNUP,
  API.VALIDATE_RESET_PASSWORD,
];

export { publicEndpoints };

export default API;
